import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import { showUnexpectedError } from 'packages/lib/toast';
import { useSession } from 'packages/lib/useSession';
import { nextToExternal } from '@api/auth/session';
import { useAuthNavigation, LoginRoutes } from '../../../routes/routes-config';
import styles from '../login.css';

export default function ActiveDirectoryCallbackSuccess(props: any) {
  const { updateSession } = useSession();
  const navigate = useAuthNavigation();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await nextToExternal();

        updateSession(data);
      } catch (e) {
        showUnexpectedError(e as AxiosError);
        navigate(LoginRoutes.ActiveDirectory);
      }
    }

    fetchData();
  }, []);

  return (
    <div className={styles.content}>
      <div className={styles.redirecting}>Processing...</div>
    </div>
  );
}
